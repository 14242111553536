<template>
  <article class="user-section">
    <h1 class="text-secondary">
      Meus Médicos
    </h1>
    <div
      v-if="!ui.isLoading && doctorList.length === 0"
      class="text-neutral text-center mt-10"
    >
      <p class="font-bold text-sm mb-4">
        Você ainda não possui consultas agendadas para poder visualizar os seus médicos
      </p>

      <p class="text-sm">
        Clique no botão abaixo e agende sua consulta por telemedicina ou presencial
      </p>

      <button class="button button--primary mx-auto mt-10 mb-6 !px-10">
        Agendar minha primeira consulta
      </button>
    </div>

    <transition-group
      v-else
      class="flex flex-wrap sm:block w-full"
      tag="ul"
      name="fade"
    >
      <li
        v-for="(doctor, $index) in doctorList"
        :key="`doctor${$index}`"
        class="flex md:w-6/12"
      >
        <figure class="flex w-full m-4 p-6 rounded-xl shadow-md  box-border">
          <img
            :src="doctor.profileImage"
            :style="`background-image:url(${doctor.profileImage})`"
            class="w-12 h-12 flex-shrink-0 rounded-md border-2 border-primary bg-no-repeat bg-cover"
            @error="imgNotFound($event, doctor)"
          >

          <figcaption class="px-4">
            <h3 class="text-primary mb-1">
              {{ doctor.display_name }}
            </h3>

            <p class="text-xs">
              CRM {{ doctor.crm }}
            </p>

            <p
              v-if="doctor.medical_specialties.length > 0"
              class="text-xs font-bold"
            >
              <template v-for="(ms, $index2) of doctor.medical_specialties">
                {{ ms.name.toLowerCase() }}
                {{ $index2 === doctor.medical_specialties.length - 1? '.' : ',' }}
              </template>
            </p>
          </figcaption>

          <!-- TODO -->
          <div class="flex ml-auto flex-col justify-end items-end">
            <ui-icon
              v-if="false"
              name="bookmark"
              color="secondary"
              class="w-4"
            />

            <router-link
              :to="{ name: 'UserDoctorAgenda', params: { doctorId: doctor.id } }"
              class="text-secondary text-xs font-bold whitespace-no-wrap cursor-pointer"
            >
              Ver agenda
            </router-link>
          </div>
        </figure>
      </li>
    </transition-group>

    <footer v-if="doctorList.length > 0">
      <ui-button
        :disabled="ui.isFetching || pagination.currentPage === pagination.totalPages"
        label="Carregar mais"
        class="m-auto !px-8 mt-4"
        @click="fetch(pagination.currentPage + 1)"
      />
    </footer>
  </article>
</template>

<script>
import { mapActions } from 'vuex';
import handleError from '@/mixins/handleError';
import handleSuccess from '@/mixins/handleSuccess';
import myDoctors from '@/api/user/myDoctors';
import UiIcon from '@/components/ui/Icon.vue';
import UiButton from '@/components/ui/Button.vue';
import placeholderImage from '@/assets/img/icons-component/Logo.svg';

export default {
  components: {
    UiIcon,
    UiButton,
  },

  mixins: [handleError, handleSuccess],

  data() {
    return {
      myDoctors: null,
      doctorList: [],
      pagination: {},
      placeholderImage,
      ui: {
        loaded: true,
        modalActive: false,
        isLoading: false,
        isFetching: false,
      },
    };
  },

  computed: {
    returnPx() {
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    },
  },

  mounted() {
    this.ui.isLoading = true;
    this.myDoctors = myDoctors(this.$store.getters['user/headers']);
    this.doctorList = [];
    this.fetch();
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'openModalDialog',
      'closeModalDialog',
    ]),

    returnProfilePicture(id) {
      return `${process.env.VUE_APP_BASE_API}/s3/file/doctor/${id}/profile_picture?${Date.now()}`;
    },

    previewImage(file) {
      this.loadedImage = true;
      this.uploadForm.profileImage.value = file;
    },

    imgNotFound(ev, doctor) {
      ev.target.src = this.returnPx;
      doctor.profileImage = this.placeholderImage;
    },

    fetch(page) {
      this.toggleProgressBar(true);
      this.ui.isFetching = true;

      this.myDoctors
        .list(page)
        .then((res) => {
          this.pagination = res.pagination;
          for (const doctor of res.data) {
            doctor.profileImage = this.returnProfilePicture(doctor.id);
            this.doctorList.push(doctor);
          }
          this.ui.isFetching = false;
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },
  },
};
</script>
