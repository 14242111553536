import http from '../httpConfig';

const myDoctors = (config) => {
  const headers = config;

  const list = (page) => new Promise((resolve, reject) => {
    http.get(`/user/agenda/doctor?order[d.displayName]=ASC&${page ? `page=${page}` : ''}`, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => reject());
  });

  const getDoctor = (doctorId) => new Promise((resolve, reject) => {
    const url = `/user/agenda/doctor?filterAnd[d.id]=${doctorId}`;

    http.get(url, headers)
      .then((res) => {
        if (res.data.data.length > 0) {
          resolve(res.data.data[0]);
          return;
        }

        reject(new Error('Médico não encontrado'));
      })
      .catch((err) => reject(err.response.data));
  });

  return {
    list,
    getDoctor,
  };
};

export default myDoctors;
